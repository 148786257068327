<template>
 <div class="work-order-gather">
   <van-sticky>
     <van-nav-bar
         left-arrow
         left-text="返回"
         @click-left="goBack"
         :title="moduleTitle"
     />
   </van-sticky>
   <van-radio-group v-model="selDateType" direction="horizontal" class="date-radio">
     <van-radio name="1">七天</van-radio>
     <van-radio name="2">近一月</van-radio>
     <van-radio name="3">自定义</van-radio>
     <van-button type="primary" v-show="selDateType !== '3'" @click="onConfirm">查询</van-button>
   </van-radio-group>
   <van-cell-group class="date-cell-group" v-show="selDateType === '3'">
     <van-cell title="选择日期区间" :value="selDate" @click="show = true" is-link/>
     <van-calendar v-model="show" type="range" @confirm="onConfirm" :min-date="minDate" :allow-same-day="true"/>
   </van-cell-group>
   <template v-for="(drawTotalDiv,index) in drawTotalDivs">
     <div :id="'drawTotalDiv' + index" style="width: 100%;height:400px;" class="draw-div"></div>
   </template>
   <template v-for="(drawDiv,index) in drawDivs">
     <div :id="'drawDiv' + index" style="width: 100%;height:400px;" class="draw-div"></div>
   </template>
 </div>
</template>

<script>

export default {
  name: "Analysis1002",
  data() {
    return {
      moduleTitle: '',
      selDateType: '1',
      minDate: new Date(2020,1,1),
      show: false,
      miniReqParams: {
        //0=今天，1=时间区间
        timeConditionType: '0',
        startDate: '',
        endDate: '',
        month: ''
      },
      drawDivs: [],
      drawTotalDivs: [],
      existCharts: []
    }
  },
  methods:{
    goBack() {
      this.$router.back();
    },
    onConfirm(date) {
      if(this.selDateType === '3') {
        const [start, end] = date;
        this.show = false;
        this.miniReqParams.timeConditionType = '1';
        this.miniReqParams.startDate = this.dateUtils.formatDate(start);
        this.miniReqParams.endDate = this.dateUtils.formatDate(end);
      } else {
        if(this.selDateType === '1') {
          this.miniReqParams.startDate = this.dateUtils.getBeforeDate(7);
          this.miniReqParams.endDate = this.dateUtils.formatDate(new Date());
        } else {
          this.miniReqParams.startDate = this.dateUtils.getBeforeDate(30);
          this.miniReqParams.endDate = this.dateUtils.formatDate(new Date());
        }
      }
      this.$toast.loading({message: '请求中', overlay: true});
      this.sendQuery();
    },
    sendQuery() {
      //发送查询请求
      this.postRequest('/wechat-analysis/power-order-gather', this.miniReqParams).then(resp => {
        // console.log(resp);
        if(resp) {
          this.drawTotalDivs = [];
          this.drawDivs = [];
          if(this.existCharts.length > 0) {
            this.existCharts.forEach(charts => {
              charts.dispose();
            })
            this.existCharts = [];
          }
          let data = resp.data;
          if(resp.attachObj && resp.attachObj.length > 0) {
            resp.attachObj.forEach((value, index) => {
              this.drawTotalDivs.push(index);
            })
            this.$nextTick(() => {
              this.drawTotalCharts(resp.attachObj);
            })
          }
          if(data) {
            console.log(data)
            data.company.forEach((company, index) => {
              let pageNum = parseInt((data.legendData[company].length - 1) / 4 + 1);
              for (let i = 0; i < pageNum; i++) {
                this.drawDivs.push(i);
              }
            })
            //功率分组显示
            // data.forEach(value => {
            //   Object.keys(value).forEach(key => {
            //     Object.keys(value[key]).forEach((k, i) => {
            //       this.drawDivs.push(i);
            //     })
            //   });
            // })
            this.$nextTick(() => {
              this.drawCharts(data);
            })
          } else {
            this.$toast("查询无数据，请换条件查询！")
          }
        }
      })
    },
    drawTotalCharts(data) {
      data.forEach((company,i) => {
        Object.keys(company).forEach(companyKey => {
          let params = {};
          //标题
          params.title = companyKey;
          //线条名称
          params.legend = ['完工设备台数','新增设备台数']
          let xAxisData = [];
          let cutData = [];
          let numberData = [];
          company[companyKey].forEach((obj, index) => {
            xAxisData.push(obj.pnamew);
            cutData.push(obj.cut);
            numberData.push(obj.number);
          })
          params.xAxis = xAxisData;
          params.series = [
            {
              name: params.legend[0],
              type: 'bar',
              data: cutData
            },
            {
              name: params.legend[1],
              type: 'bar',
              data: numberData
            }
          ];
          this.totalEcharts('drawTotalDiv' + i, params);
        })
      })
    },
    drawCharts(data) {
      let legendGroup = [' 完工设备台数',' 新增设备台数'];
      let columnsGroup = ['Cut','Number'];
      let divCount = 0;
      data.company.forEach((company, companyIndex) => {
        let paramsList = [];
        let params = {};
        params.title = company;
        params.xAxis = data.xaxisData;
        params.legend = [];
        params.series = [];
        data.legendData[company].forEach((legend, legendIndex) => {
          let cutLegendName = legend + legendGroup[0];
          let numberLegendName = legend + legendGroup[1];
          let cutName = columnsGroup[0] + legend;
          let numberName = columnsGroup[1] + legend;
          params.legend.push(cutLegendName);
          params.legend.push(numberLegendName);
          params.series.push({
            name: cutLegendName,
            type: 'line',
            stack: cutLegendName,
            data: data.seriesData[company][cutName]
          })
          params.series.push({
            name: numberLegendName,
            type: 'line',
            stack: numberLegendName,
            data: data.seriesData[company][numberName]
          })
          if(legendIndex == (data.legendData[company].length - 1) || (legendIndex + 1) % 4 == 0) {
            paramsList.push(params);
            this.myEcharts('drawDiv' + divCount, paramsList, paramsList.length -1);
            params.legend = [];
            params.series = [];
            divCount++;
          }
        });

      })
      //按功率分组展示
      // let legendGroup = ['完工设备台数','新增设备台数'];
      // let columnsGroup = ['cut','number'];
      // let divCount = 0;
      // data.forEach(company => {
      //   Object.keys(company).forEach(companyKey => {
      //     Object.keys(company[companyKey]).forEach((powerKey, index) => {
      //       let params = {};
      //       //标题
      //       params.title = companyKey + " " + powerKey;
      //       //线条名称
      //       params.legend = legendGroup;
      //       params.columns = columnsGroup;
      //       //日期
      //       params.showDates = [];
      //       //展示的内容
      //       params.series = [];
      //       params.columns.forEach((column, columnIndex) => {
      //         let columnData = [];
      //         company[companyKey][powerKey].forEach(item => {
      //           columnData.push(item[column]);
      //           if(columnIndex == 0) {
      //             params.showDates.push(item.udate)
      //           }
      //         })
      //         let obj =  {
      //           name: params.legend[columnIndex],
      //           type: 'line',
      //           stack: column,
      //           data: columnData
      //         }
      //         params.series.push(obj);
      //       })
      //       this.myEcharts('drawDiv' + divCount, params);
      //       divCount++;
      //     })
      //   });
      // });
    },
    myEcharts(id, paramsList, index){
      // 基于准备好的dom，初始化echarts实例
      let myChart = this.$echarts.init(document.getElementById(id));

      // 指定图表的配置项和数据
      let option = {
        title: {
          text: paramsList[index].title
        },
        tooltip: {
          trigger: 'axis',
          confine: true,
        },
        legend: {
          show: true,
          top: '6%',
          data: paramsList[index].legend
        },
        grid: {
          top: '20%',
          left: '3%',
          right: '11%',
          bottom: '1%',
          containLabel: true
        },
        xAxis: {
          type: 'category',
          boundaryGap: false,
          data: paramsList[index].xAxis
        },
        yAxis: [{
          type: 'value',
          axisTick: {
            inside: true
          },
          scale: true,
          axisLabel: {
            margin: 2,
            formatter: function (value, index) {　　
              //y轴的数值显示格式化主要调整部分
              if (value >= 10000 && value < 10000000) {
                value = value / 10000 + "万";
              }
              return value;
            }
          },
          "splitLine": {//网格线 默认true
            "show": true
          }
        }],
        series: paramsList[index].series
      };

      // 使用刚指定的配置项和数据显示图表。
      myChart.setOption(option);
      this.existCharts.push(myChart);
    },
    totalEcharts(id, params) {
      // 基于准备好的dom，初始化echarts实例
      let myChart = this.$echarts.init(document.getElementById(id));
      let option = {
        title: {
          text: params.title + " 安装工单功率汇总"
        },
        tooltip: {
          trigger: 'axis',
          confine: true,
          axisPointer: {
            type: 'cross',
            crossStyle: {
              color: '#999'
            }
          }
        },
        grid: {
          top: '20%',
          left: '3%',
          right: '11%',
          bottom: '5%',
          containLabel: true
        },
        legend: {
          top: '6%',
          data: params.legend
        },
        xAxis: [
          {
            type: 'category',
            data: params.xAxis,
            axisPointer: {
              type: 'shadow'
            }
          }
        ],
        yAxis: [
          {
            type: 'value',
            axisLabel: {
              margin: 2,
              formatter: function (value, index) {
                //y轴的数值显示格式化主要调整部分
                if (value >= 10000 && value < 10000000) {
                  value = value / 10000 + "万";
                }
                return value;
              }
            },
          }
        ],
        dataZoom:[
          {
            show: true,
            start: 0,
            end: 30,
            height: 10,
            bottom: 10,
          },
          {
            type: 'inside',
            start: 0,
            end: 30,
          },
        ],
        series: params.series
      };

      myChart.setOption(option);
      this.existCharts.push(myChart);
    }
  },
  computed: {
    selDate() {
      if(this.miniReqParams.startDate.length > 0
          && this.miniReqParams.endDate.length > 0) {
        return `${this.miniReqParams.startDate}-${this.miniReqParams.endDate}`
      }
    }
  },
  mounted: function () {
    this.moduleTitle = window.sessionStorage.getItem("moduleTitle");
  }
}
</script>

<style scoped>
.date-radio {
  margin-top: 15px;
  background-color: #fff;
  padding: 10px 16px;
}
.date-radio>button {
  margin-left: auto;
}
.draw-div {
  margin-top: 15px;
  background-color: white;
}
.van-nav-bar__content {
  margin-bottom: 10px;
}
.work-order-gather {
  padding-bottom: 15px;
  width: 100%;
}
.date-cell-group {
  margin: 15px 0;
}
.van-cell__value {
  color: #999;
}
</style>